<template>
  <div>
    <div class="flex">
      <slot />
      <VLabel
        :text="label"
        :name="name"
        :is-required="isRequired"
        :size="labelSize"
        :class="{ 'sr-only': isLabelHidden }"
      />
    </div>
    <div class="relative mt-1 rounded-md shadow-sm">
      <input
        :id="name"
        v-model="inputValue"
        lang="de"
        :name="name"
        :type="fieldType"
        :min="setMin"
        :max="setMax"
        :placeholder="placeholder"
        :autocomplete="autocomplete"
        :disabled="disabled"
        :class="{ 'border-cred': !!errorMessage }"
        class="block w-full py-3 text-gray-900 border-gray-500 rounded-md shadow-sm disabled:bg-gray-100 disabled:text-gray-500 focus:ring-cblack focus:border-cblack sm:text-sm"
        @change="handleChange"
        @blur="onBlurHandler"
      />

      <div
        v-if="isClearButtonVisible"
        class="absolute inset-y-0 flex items-center right-3.5"
      >
        <button
          aria-label="clear"
          class="inline-flex items-center justify-center p-1.5 bg-gray-300 rounded-full hover:bg-gray-400"
          @click="onClickClearButton()"
        >
          <Icon
            name="heroicons:x-mark-solid"
            size="16"
            aria-hidden="true"
            class="text-white"
          />
        </button>
      </div>

      <div
        v-if="isShowPasswordButtonEnabled"
        class="absolute inset-y-0 flex items-center right-3.5"
      >
        <button
          type="button"
          :aria-label="isShowPassword ? 'hide' : 'show'"
          class="inline-flex"
          @click="togglePasswordShow()"
        >
          <Icon
            v-if="isShowPassword"
            name="heroicons:eye"
            size="20"
            class="text-cblack"
          />

          <Icon
            v-else
            name="heroicons:eye-slash"
            size="20"
            class="text-cblack"
          />
        </button>
      </div>

      <div
        v-show="errorMessage"
        class="absolute inset-y-0 flex items-center pr-3 pointer-events-none"
        :class="
          isShowPasswordButtonEnabled || type === 'number'
            ? 'right-6'
            : 'right-0'
        "
      >
        <Icon
          name="heroicons:exclamation-circle-solid"
          size="28"
          aria-hidden="true"
          :class="{ 'mr-6': type === 'date' }"
          class="text-cred"
        />
      </div>
    </div>

    <p v-show="errorMessage && showError" class="mt-2 text-sm text-cred">
      {{ errorMessage }}
    </p>
  </div>
</template>

<script setup lang="ts">
import { computed, watch, ref } from 'vue'
import { Size } from '@/types/enums'
import { useField } from 'vee-validate'
import * as yup from 'yup'
import VLabel from '@/components/VLabel.vue'

const props = defineProps({
  type: {
    type: String,
    default: 'text',
  },
  value: {
    type: [String, Number],
    default: () => {
      return ''
    },
  },
  modelValue: {
    type: [String, Number],
    default: '',
    required: false,
  },
  name: {
    type: String,
    required: true,
  },
  label: {
    type: String,
    required: true,
  },
  successMessage: {
    type: String,
    default: '',
  },
  placeholder: {
    type: String,
    default: null,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  isClearButtonEnabled: {
    type: Boolean,
    default: false,
  },
  isRequired: {
    type: Boolean,
    default: false,
  },
  autocomplete: {
    type: String,
    default: '',
  },
  labelSize: {
    type: String as PropType<Size>,
    default: Size.sm,
  },
  showError: {
    type: Boolean,
    default: true,
  },
  min: {
    type: [Number, String],
    default: null,
  },
  max: {
    type: [Number, String],
    default: null,
  },
  rules: {
    type: Object as PropType<yup.BaseSchema<any>>,
    default: () => {
      undefined
    },
  },
  isShowPasswordButtonEnabled: {
    type: Boolean,
    default: false,
  },
  isLabelHidden: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['clear'])

const isShowPassword = ref<boolean>(props.type != 'password')
const fieldType = ref(props.type)

const {
  value: inputValue,
  errorMessage,
  handleBlur,
  handleChange,
  resetField,
} = useField(props.name, props.rules, {
  initialValue: props.value,
  syncVModel: true,
})

watch(inputValue, (newValue) => {
  if (props.type === 'tel' && newValue && typeof newValue === 'string') {
    inputValue.value = newValue
      .replace(/[^0-9+ ]/g, '')
      .replace(/(\..*)\./g, '$1')
  }
})

function onBlurHandler(event: Event) {
  if (['text', 'email'].includes(props.type)) {
    const inputValue = (event.target as HTMLInputElement).value
    handleChange(inputValue.trim())
  }
  handleBlur(event)
}

// MARK: Clear Button

const isClearButtonVisible = computed(() => {
  if (typeof inputValue.value === 'string') {
    return props.isClearButtonEnabled && inputValue.value.length > 0
  }
  return false
})

function onClickClearButton() {
  resetField()
  emit('clear')
}

function togglePasswordShow() {
  isShowPassword.value = !isShowPassword.value
  if (fieldType.value === 'password') return (fieldType.value = 'text')
  else return (fieldType.value = 'password')
}

const setMin = computed(() => {
  if (props.type === 'date' || props.type === 'number') {
    return props.min != null ? props.min : 0
  }

  return undefined
})

const setMax = computed(() => {
  if (props.type === 'number') {
    return props.max != null ? props.max : 9999999
  }

  if (props.type === 'date') {
    return props.max != null ? props.max : undefined
  }

  return undefined
})
</script>
